.privacy-policy-container1 {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #F5F7FA;
}
.privacy-policy-hero {
  width: 100%;
  display: flex;
  padding: 0;
  position: relative;
  align-items: center;
  flex-direction: column;
  background-color: #F5F7FA;
}
.privacy-policy-header-simple {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  position: relative;
  max-width: 900px;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: var(--dl-space-space-twounits);
}
.privacy-policy-logo-link {
  display: contents;
}
.privacy-policy-logo {
  height: 50px;
  text-decoration: none;
  transition: 0.3s;
}
.privacy-policy-logo:hover {
  opacity: 0.85;
}
.privacy-policy-hero-content {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: 900px;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
}
.privacy-policy-content-container {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.privacy-policy-text-container1 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.privacy-policy-text05 {
  color: #2C3E50;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  margin-bottom: 0;
  text-transform: none;
}
.privacy-policy-text06 {
  color: #7F8C8D;
  font-size: 16px;
  font-style: italic;
  margin-top: 0;
  font-weight: 400;
  margin-bottom: var(--dl-space-space-twounits);
}
.privacy-policy-text07, 
.privacy-policy-text09, 
.privacy-policy-text11, 
.privacy-policy-text13, 
.privacy-policy-text15, 
.privacy-policy-text17, 
.privacy-policy-text19 {
  color: #2C3E50;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-halfunit);
}
.privacy-policy-text08, 
.privacy-policy-text10, 
.privacy-policy-text12, 
.privacy-policy-text14, 
.privacy-policy-text16, 
.privacy-policy-text18, 
.privacy-policy-text20 {
  color: #34495E;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: var(--dl-space-space-unit);
}
.privacy-policy-footer {
  width: 100%;
  display: flex;
  max-width: 100%;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: center;
  background-color: #1e64ec;
}
.privacy-policy-container2 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
}
.privacy-policy-row {
  width: 100%;
  display: flex;
  grid-gap: var(--dl-space-space-threeunits);
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.privacy-policy-column {
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.privacy-policy-column-header {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.privacy-policy-column-links {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.privacy-policy-link, 
.privacy-policy-navlink2, 
.privacy-policy-navlink3 {
  color: rgb(201, 203, 217);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 24px;
  text-decoration: none;
}
.privacy-policy-link:hover, 
.privacy-policy-navlink2:hover, 
.privacy-policy-navlink3:hover {
  color: white;
  text-decoration: underline;
}
.privacy-policy-bottom {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  border-color: rgb(80, 91, 219);
  border-width: 1px;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.privacy-policy-branding {
  height: 40px;
  filter: brightness(0) invert(1);
}
.privacy-policy-text30 {
  color: rgb(201, 203, 217);
  font-size: 14px;
}
@media(max-width: 991px) {
  .privacy-policy-hero {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .privacy-policy-header-simple {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .privacy-policy-hero-content {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 767px) {
  .privacy-policy-hero-content {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .privacy-policy-footer {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .privacy-policy-row {
    flex-wrap: wrap;
    justify-content: center;
  }
  .privacy-policy-column {
    min-width: 200px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .privacy-policy-bottom {
    flex-direction: column;
    justify-content: center;
  }
  .privacy-policy-branding {
    margin-bottom: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .privacy-policy-header-simple {
    padding: var(--dl-space-space-unit);
  }
  .privacy-policy-logo {
    height: 40px;
  }
  .privacy-policy-hero-content {
    padding: var(--dl-space-space-unit);
  }
  .privacy-policy-row {
    gap: var(--dl-space-space-unit);
  }
} 